import { FC, useMemo } from 'react'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import { useNotificationsList } from 'services/notifications/notifications.hook'
import Dial from 'components/Dial'
import { useLeaseContractsList } from 'services/contracts/hooks/useLeaseContracts'

const BoardsMessage: FC = () => {
  const { messagesList } = useNotificationsList()
  const { formatMessage } = useIntl()
  const { id } = useParams()
  const { leaseContractsList } = useLeaseContractsList()

  const activeLeases = leaseContractsList?.filter(({ leaseStatus }) => leaseStatus === 0) || []
  const navigate = useNavigate()

  const message = useMemo(
    () => messagesList.find((meg) => meg.id === Number(id)),
    [messagesList, id]
  )

  if (message) {
    return (
      <>
        <BackNavigationHeader
          navigateTo='/board'
          desktopNavigateTo='/'
          label={
            message.subject ??
            formatMessage(
              {
                id: 'moardsMessage.title',
                defaultMessage: 'Wiadomość {id}'
              },
              {
                id
              }
            )
          }
          sx={{
            backgroundColor: 'rgba(29, 34, 110, 0.7)',
            color: '#fff',
            '.MuiIconButton-root': {
              color: '#fff'
            },
            zIndex: 1000
          }}
        />
        <BackNavigationContent sx={{ position: 'relative' }}>
          <Typography
            component='div'
            width='100%'
            overflow='scroll'
            dangerouslySetInnerHTML={{ __html: message.renderedEmail }}
          />
          {activeLeases?.length ? (
            <Dial ariaLabel='' onClick={() => navigate('/board/new-event')} />
          ) : null}
        </BackNavigationContent>
      </>
    )
  }
  return null
}

export default BoardsMessage
